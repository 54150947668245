.S3FileUploader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
}

.S3FileUploader .dx-button-mode-contained .dx-icon {
    color: var(--mp-primary-color);
}

@media screen and (max-width: 40rem) {
    .S3FileUploader {
        flex-direction: column;
    }
}