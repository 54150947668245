@font-face {
  font-family: "Lato Bold";
  src: url('./fonts/lato/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url('./fonts/lato/Lato-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.App {
  min-height: 100vh;
  height: 100vh;

  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

:root {
  --content-wrapper-width: 70em;
  --mp-primary-color: #3f5d64;
  --mp-secondary-color: #35a8e0;
  --mp-warning-color: #ffc107;
  --mp-confirmation-color: #198754;
  --mp-text-color-dark: black;
  --mp-text-color-bright: white;
  --mp-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --mp-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --mp-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --mp-body-font-family: var(--mp-font-sans-serif);
  --amplify-components-button-primary-background-color: var(--mp-primary-color) !important;
  --amplify-components-button-link-color: var(--mp-secondary-color) !important;
}

h2, .h2 {
  font: var(--mp-font-sans-serif);
  font-weight: 400;
  font-size: 30px;
  line-height: 1.8;
  color: white;
}

.App-logo {
  padding: 1rem;
  max-height: 5rem;
  pointer-events: none;
}

.Header {
  padding: 1rem 1rem 5rem 1rem;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {text-decoration: none;}

.PageNotFound, .Landingpage,
.UserManagementView, .WatermeterImport,
.CitizenImport, .Dashboard,
.CustomerManagementView, .DeviceManagementView,
.StockLocationManagementView, .PeriodManagementView, .MeteringPointManagementView, .TourManagementView,
.TourTaskManagementView, .TourTechnicianView, .TourTaskTechnicianView {padding: 1rem;}
.dx-datagrid .dx-link {color: var(--mp-primary-color) !important;}
.dx-pager .dx-page-sizes .dx-selection, .UserManagementView .dx-pager .dx-pages .dx-selection {
  background-color: var(--mp-primary-color) !important;
}

.UserManagementView .userStatus.active {color: green;}
.UserManagementView .userStatus.deactivated {color: darkorange;}
.UserManagementView .userStatus.removed {color: red;}

/*CustomerManagementView*/
.customer.address {
  display: flex;
  flex-direction: column;
}

.centerHor{margin: auto; width: fit-content;}
.Button.center {
  width:fit-content;
  margin:auto;
}

.dx-treeview-item .dx-treeview-item-content > .dx-icon {margin-right:0.8rem !important;}

.exp {color: darkorange; padding: 1em; margin: 1em; border: 1px solid; text-align: center}
.exp:before {content: "Test Version"}

.react-html5-camera-photo, .react-html5-camera-photo>img, .react-html5-camera-photo>video {
  width: 100%;
  max-height: 100%;
}

/* datagrids in general */
.dx-datagrid-header-panel {
  border: 0;
  padding: 0;
}


/* TourTechnicianView */
.TourTechnicianView .dx-data-row {
  cursor: pointer;
}

.TourTechnicianView .TourDescription {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.TourTechnicianView .TourDescription .buchNr,
.TourTaskTechnicianView .TourTaskDescription .deviceId{
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--mp-primary-color);
}

.TourTechnicianView .TourTasksStatusSummary {
  display: flex;
  gap: 1em;
}

.furtherAction{
  color: red;
}

.noCustomer{
  color: orange;
}
.done{
  color: green;
}

/* TourTaskTechnicianView */
.TourTaskTechnicianView .dx-data-row {
  cursor: pointer;
}

.TourTaskTechnicianView .TourTaskDescription {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.TourTaskTechnicianView .TourTaskDescription .header{
  display: flex;
  gap: 0.5em;
}

.TourTaskTechnicianView .TourTaskDescription .address{
  font-weight: 700;
}

.TourTaskTechnicianView .TourTaskDescription .customer{
  color: gray;
}

.TourTaskTechnicianView .TourTaskStatus{
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
}

.task-button.notSelected .dx-icon{
  color: gray;
}

.task-button.furtherAction .dx-icon{
  color: red;
}

.task-button.noCustomer .dx-icon{
  color: orange;
}
.task-button.done .dx-icon{
  color: green;
}